import React, { useCallback } from 'react';
import { DevelopmentLabel } from '~/components/developments/DevelopmentLabel';
import { envManager } from '~/constants/envManager';
import { useFeatureFlag } from '~/hooks/common/useFeatureFlag';

export const useEnvironmentViewer = () => {
  const featureFlag = useFeatureFlag();

  const render = useCallback(() => {
    return (
      <>
        {envManager.publicEnv.environment !== 'production' && (
          <DevelopmentLabel
            branchName={envManager.publicEnv.gitBranchName}
            flagList={featureFlag.state.activeFeatures}
          />
        )}
      </>
    );
  }, [featureFlag.state]);

  return {
    render,
  };
};
