import React, { createContext, useContext, useEffect, useState } from 'react';
import { localStorageKeys } from '~/constants/localStorageKeys';
import { Message, MessageHandler } from '~/types/message';
import { useLocalStorage } from '~/hooks/common/useLocalStorage';
import { useRouter } from 'next/router';

// State, Action
export type FlashMessagesState = {
  messages: Message[];
  isReady: boolean;
};

export type FlashMessagesActions = {
  pushMessage: MessageHandler;
  directSetMessage: MessageHandler;
  deleteMessage: (idx: number) => void;
  routerReload: () => void;
};

// Context type
export type FlashMessagesContext = {
  state: FlashMessagesState;
  actions: FlashMessagesActions;
};

const defaultValue: FlashMessagesContext = {
  state: {
    messages: [],
    isReady: false,
  },
  actions: {
    pushMessage: () => {},
    directSetMessage: () => {},
    deleteMessage: () => {},
    routerReload: () => {},
  },
};

const FlashMessagesContext = createContext<FlashMessagesContext>(defaultValue);

const INITIAL_MESSAGES: Message[] = [];

export const FlashMessagesProvider: React.FC<{ children: React.ReactNode; pathname: string }> = ({
  children,
  pathname,
}) => {
  const [displayedMessages, setDisplayedMessages] = useState<Message[]>(
    defaultValue.state.messages
  );

  const storageMessages = useLocalStorage<Message[]>(
    localStorageKeys.flashMessages.message,
    INITIAL_MESSAGES
  );
  const storageReloadFlag = useLocalStorage<boolean>(
    localStorageKeys.flashMessages.reloadFlag,
    false
  );

  const router = useRouter();
  const [prevPath, setPrevPath] = useState<string | null>(null);
  const [currentPath, setCurrentPath] = useState<string | null>(null);

  const [routeChanging, setRouteChanging] = useState(false);

  const routeChangeStart = () => setRouteChanging(true);
  const routeChangeComplete = () => setRouteChanging(false);

  // NOTE: ルート遷移中にpushMessageすると正しく表示されないため
  useEffect(() => {
    router.events.on('routeChangeStart', routeChangeStart);
    router.events.on('routeChangeComplete', routeChangeComplete);
    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
      router.events.off('routeChangeComplete', routeChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPrevPath(currentPath);
    setCurrentPath(router.asPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  useEffect(() => {
    if (storageMessages.loading) return;

    // NOTE: Railsからのリダイレクト時にはセットしたフラッシュメッセージは使わない
    if (prevPath !== null || storageReloadFlag.value) setDisplayedMessages(storageMessages.value);
    storageMessages.set([]);
    storageReloadFlag.set(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageMessages.loading, pathname]);

  const pushMessage = (newMessage: Message) => {
    storageMessages.set([...storageMessages.value, newMessage]);
  };

  const directSetMessage = (newMessage: Message) => {
    setDisplayedMessages([...displayedMessages, newMessage]);
  };

  const deleteMessage = (idx: number) => {
    const tmp = [...displayedMessages];
    tmp.splice(idx, 1);
    setDisplayedMessages(tmp);
  };

  const routerReload = () => {
    storageReloadFlag.set(true);
    router.reload();
  };

  return (
    <FlashMessagesContext.Provider
      value={{
        state: {
          messages: displayedMessages,
          isReady: !routeChanging,
        },
        actions: {
          pushMessage: pushMessage,
          directSetMessage: directSetMessage,
          deleteMessage: deleteMessage,
          routerReload,
        },
      }}
    >
      {children}
    </FlashMessagesContext.Provider>
  );
};

export const useFlashMessages = () => useContext(FlashMessagesContext);
